import React from "react";
import Fullscreen from "./Fullscreen";
import anchorage_rental_house_colombo_srilanka_logo from "../assets/anchorage_rental_house_colombo_srilanka_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

const ContactsSection = () => {
  const contactDetails = [
    { name: "WhatsApp 1:", data: "+94 77 775 6814" },
    { name: "WhatsApp 2 (message):", data: "+94 70 775 6814" },
    {
      data: "For urgent matters post-booking, call or text either number directly.",
    },
    { name: "Email:", data: "ajanthaabey85@gmail.com" },
    { name: "BNR Number:", data: "WP/COL/SJPK/2024/00109" },
  ];

  const handleScrollTop = () => {
    const element = document.getElementById("HomeSection");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Fullscreen backgroundColor="#000080" minHeight="60vh" alignContent="end">
      <div
        className="relative grid md:grid-cols-6 grid-cols-1 w-screen text-white "
        id="ContactsSection"
      >
        <div className="lg:col-span-1 md:col-span-2 p-5 content-end z-10 ">
          <img
            src={anchorage_rental_house_colombo_srilanka_logo}
            className="md:px-8 h-32 py-4"
            alt="A&N anchorage logo"
          />
          <p className="md:px-8 font-bold lg:text-4xl text-3xl">
            Let's talk about your next trip
          </p>
        </div>
        <div className="lg:col-span-3 md:col-span-2 p-5 z-10">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15843.674267225806!2d79.8875165!3d6.9003423!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2592b6875ac03%3A0xccdfbc9d13e5d155!2sA%26N%20Anchorage!5e0!3m2!1sen!2slk!4v1721203120125!5m2!1sen!2slk"
            className="w-full h-full"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        {/* Contact Details */}
        <div className="md:col-span-2 p-5 z-10">
          <div className="p-2">
            <p className="font-bold md:text-xl text-lg">Address</p>
            <p className="md:text-xl text-lg">21/3 2nd Lane,</p>
            <p className="md:text-xl text-lg">Galpotta Road,</p>
            <p className="md:text-xl text-lg">Nawala</p>
            <p className="md:text-xl text-lg">Sri Lanka</p>
          </div>
          <div>
            <p className="px-2 pt-5 font-bold md:text-xl text-lg">Contact</p>
            {contactDetails.map((contact, index) => (
              <div className="grid md:grid-cols-6 px-2" key={index}>
                <div className="md:col-span-2">
                  <p className="md:text-xl text-lg">{contact.name}</p>
                </div>
                <div className="md:col-span-4">
                  {contact.name ? (
                    <p className="md:text-xl text-lg">{contact.data}</p>
                  ) : (
                    <p className="md:text-lg text-md italic mb-2">
                      {contact.data}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-center pb-5 px-5">
        <a
          href="/#HomeSection"
          onClick={(e) => {
            e.preventDefault();
            handleScrollTop();
          }}
          className="px-4 z-10"
          style={{ textShadow: "0px 0px 3px #000000" }}
        >
          <FontAwesomeIcon
            icon={faChevronUp}
            className="p-2 text-3xl bg-opacity-40 bg-white rounded-xl"
            style={{ color: "#FFFFFF", borderColor: "#FFFFFF" }}
          />
        </a>
      </div>
    </Fullscreen>
  );
};

export default ContactsSection;
