import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeSection from "./components/HomeSection";
import AboutUsSection from "./components/AboutUsSection";
import GalleryOutdoorSection from "./components/GalleryOutdoorSection";
import GalleryIndoorSection from "./components/GalleryIndoorSection";
import TourismLicenseSection from "./components/TourismLicenseSection";
import TourismRegistrationSection from "./components/TourismRegistrationSection";
import FacilitiesSection from "./components/FacilitiesSection";
import ReviewsSection from "./components/ReviewsSection";
import TourSection from "./components/TourSection";
import TermsSection from "./components/TermsSection";
import ContactsSection from "./components/ContactsSection";
import PageNotFound from "./components/PageNotFound";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        // Loading spinner
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div
            className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-[#000080] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      ) : (
        <Router>
          <Routes>
            {/* All pages */}
            <Route
              exact
              path="/"
              element={
                <>
                  <HomeSection />
                  <AboutUsSection />
                  <GalleryOutdoorSection />
                  <GalleryIndoorSection />
                  <TourismLicenseSection />
                  <TourismRegistrationSection />
                  <FacilitiesSection />
                  <ReviewsSection />
                  <TourSection />
                  <TermsSection />
                  <ContactsSection />
                </>
              }
            />
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
