import React from "react";
import Fullscreen from "./Fullscreen";
import anchorage_rental_house_colombo_srilanka_logo from "../assets/anchorage_rental_house_colombo_srilanka_logo.png";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <Fullscreen backgroundColor="#f5f5f1">
      <div className="grid place-content-center absolute top-0 w-screen h-full">
        <p className="lg:text-4xl text-3xl font-bold mb-12 text-center">
          We can't seem to find the page you're looking for.
        </p>
        <div className="flex justify-center items-center">
          <img
            src={anchorage_rental_house_colombo_srilanka_logo}
            className="md:h-72 h-32 mb-12"
            alt="A&N anchorage rental house colombo sri lanka - logo"
          />
        </div>
        <Link
          to="/"
          className="font-bold py-4 px-8 rounded-3xl m-auto uppercase text-white"
          style={{ backgroundColor: "#000080" }}
        >
          Back to the main page
        </Link>
      </div>
    </Fullscreen>
  );
};

export default PageNotFound;
